import { Fragment, useEffect, useMemo, useState } from 'react'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import ConfirmationDialog from '@/Shared/Dialog/ConfirmationDialog'
import Helpers from '@/utils/helpers'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import { Tooltip } from '@/Shared/Tooltip'

export default function CommissionsSummary({ data }) {
  const { constants, transaction } = usePage().props
  const { user } = usePage().props.auth
  const associates = transaction.contacts.filter((transactionContact) => transactionContact.contact_type === 'Associate')
  const owner = associates.find((contact) => contact.is_transaction_owner)
  const leadAssociate = owner.contact.associate
  const transaction_support_enabled = associates.some((c) => c.transaction_support)
  const svp = leadAssociate.svpContacts?.length > 0 ? leadAssociate.svpContacts[0] : null
  const minimumCapFee = Helpers.parseCurrency(constants.min_cap_fee)
  const primaryResidenceFee = Helpers.parseCurrency(constants.primary_residence_fee)
  const standardCommissionRate = Helpers.parseCurrency(constants.standard_commission_rate)
  const commissionPct =
    Helpers.parseCurrency(data.commission_blre_pct) +
    (transaction.type === 'Buyer' ? Helpers.parseCurrency(data.commission_additional_pct || 0) : 0)
  const additionalCompensations =
    data.additional_compensation?.filter((compensation) => compensation.amount && compensation.deducted_from !== 'Brokerage-Side Split') ||
    []
  const brokerageSideDeductions =
    data.additional_compensation?.filter((compensation) => compensation.deducted_from === 'Brokerage-Side Split') || []
  const teamCap = useMemo(() => {
    const payment = transaction.payments.find((payment) => payment.type === 'Team Commission' && payment.associate_id == svp?.id)
    return Helpers.parseCurrency(payment?.amount) || 0
  }, [svp?.team_cap])

  const [splits, setSplits] = useState(null)
  const [teamSplits, setTeamSplits] = useState(null)
  const [approving, setApproving] = useState(false)
  const [activeAssociate, setActiveAssociate] = useState(null)

  useEffect(() => {
    setSplits(data.splits || getSplits())
  }, [data])

  useEffect(() => {
    if (splits) {
      if (leadAssociate.cap_residual > 0 && (leadAssociate.user_id == user.id || Helpers.isAdminOrHR(user))) {
        getTeamSplits()
      }
    }
  }, [splits])

  useEffect(() => {
    if (!approving && activeAssociate) {
      setApproving(true)
    }
  }, [activeAssociate])

  useEffect(() => {
    if (!approving && activeAssociate) {
      setTimeout(() => setActiveAssociate(null), 250)
    }
  }, [approving])

  const purchasePrice = () => {
    return data.commission_basis === 'net'
      ? Helpers.parseCurrency(data.total_purchase_price) - Helpers.parseCurrency(data.closing_cost)
      : Helpers.parseCurrency(data.total_purchase_price)
  }

  const hasCommissions = () => {
    if (['Buyer', 'Seller'].some((type) => type.includes(transaction.type))) {
      return Helpers.parseCurrency(data.total_purchase_price) > 0 || splits
    }

    return splits
  }

  //prettier-ignore
  const transactionSupportFee = (split) => {
    if (!transaction_support_enabled || split.transaction_support || !split.percent) {
      return 0
    }

    const transactionSupportAssociates = splits?.filter((split) => split.transaction_support) || []
    const totalTransactionSupportAmount = transactionSupportAssociates.reduce((total, s) => total + s.amount, 0)

    return totalTransactionSupportAmount * (split.percent / 100)
  }

  const transactionSupportPct = () => {
    if (!transaction_support_enabled) {
      return 0
    }

    const transactionSupportAssociates = splits.filter((split) => split.transaction_support)

    return Helpers.truncateDecimals(transactionSupportAssociates.reduce((total, s) => total + s.percent, 0))
  }

  const netCommission = (split) => {
    let additionalCompensation = additionalCompensations
      .filter(
        (compensation) =>
          compensation.deducted_from_id == split.associate_id ||
          (compensation.deducted_from === 'Entire Associate-Side Split' && !split.transaction_support),
      )
      .reduce(
        (total, compensation) =>
          total +
          Helpers.parseCurrency(compensation.amount) /
            (compensation.deducted_from === 'Entire Associate-Side Split' ? associates.length : 1),
        0,
      )

    return (
      (split.amount || 0) -
      transactionSupportFee(split) -
      Helpers.parseCurrency(split.client_rebate_deduction || 0) -
      Helpers.parseCurrency(split.referral_deduction || 0) -
      additionalCompensation
    )
  }

  const netRetained = () => {
    return (
      Helpers.parseCurrency(data.commission_blre) +
      (transaction.type === 'Buyer' ? Helpers.parseCurrency(data.commission_additional || 0) : 0) -
      (data.referral_type === 'external' && data.referral_fee ? data.referral_fee : 0) -
      Helpers.parseCurrency(data.community_protector_rebate) -
      Helpers.parseCurrency(data.mygivebackprogram_rebate)
    )
  }

  const officeAmount = () => {
    return netRetained() - splits?.filter((split) => !split.transaction_support).reduce((total, split) => total + split.amount, 0)
  }

  const netOfficeRetained = () => {
    let sponsorCommissions = teamSplits?.reduce((total, compensation) => total + Helpers.parseCurrency(compensation.amount), 0) || 0
    let additionalCompensationPaidByBrokerage = brokerageSideDeductions.reduce(
      (total, compensation) => total + Helpers.parseCurrency(compensation.amount),
      0,
    )
    let brokerOfRecordCompensation = (netRetained() || 0) * (transaction.broker_of_record?.compensation_pct / 100)

    let net = officeAmount()
    if (!data.primary_residence) {
      net -= sponsorCommissions
    }
    net -= additionalCompensationPaidByBrokerage
    net -= brokerOfRecordCompensation || 0
    net -= teamCap

    return net
  }

  const getSplits = () => {
    let splits = associates.map((transactionContact) => {
      const associate = transactionContact.contact.associate
      const associateCap = parseFloat(associate?.cap || 0)
      const capSplit = associates.length > 1 ? transactionContact.cap_split : 100
      let basis = netRetained()
      let amount = basis * (transactionContact.split / 100)
      let officeRetained = 0

      // Only calculate agents with a brokerage-side split greater than 0
      if (transactionContact.transaction_support) {
        amount = basis * 0.7 * (transactionContact.split / 100)
      } else if (amount > 0) {
        // Use standard rate when applicable
        if (data.use_standard_rate) {
          const commission = Helpers.parseCurrency(data.commission_blre)
          basis = basis - commission + purchasePrice() * standardCommissionRate
        }

        if (associateCap > 0) {
          if (data.primary_residence && leadAssociate.user_id == transactionContact.contact.user_id) {
            amount -= officeRetained = primaryResidenceFee
          } else if (capSplit > 0) {
            const capAmount = basis * (capSplit / 100) * ((100 - associate.commission_pct) / 100)

            if (associateCap > capAmount) {
              amount -= officeRetained = capAmount
            } else {
              let capFee = Math.max(Math.min(associateCap, capAmount), minimumCapFee)
              amount -= officeRetained = capFee
            }
          }
        } else {
          const capAmount = basis * (capSplit / 100) * ((100 - associate.commission_pct) / 100)
          amount -= officeRetained = Math.min(capAmount, minimumCapFee)
        }
      }

      return {
        type: transactionContact.type,
        id: transactionContact.id,
        associate_id: associate.id,
        name: transactionContact.full_name,
        amount: amount,
        office_retained: officeRetained,
        percent: transactionContact.split,
        cap: associateCap,
        commission_pct: associate.commission_pct,
        cap_pct: capSplit,
        user_id: transactionContact.contact.user_id,
        reimbursements: transactionContact.reimbursements || null,
        client_rebate_deduction: transactionContact.client_rebate_deduction || null,
        referral_deduction: transactionContact.referral_deduction || null,
        approved: transactionContact.split_approved,
        transaction_support: transactionContact.transaction_support,
      }
    })

    return splits
  }

  const getTeamSplits = () => {
    const PRIMARY_SPONSOR_CUT = 5
    const SUPPORT_VP_CUT = 4
    const SECONDARY_SPONSOR_CUT = 1

    let team = []
    let basis = Helpers.parseCurrency(netRetained())
    let sponsorBasisPct = commissionPct > 0 && commissionPct < 2.5 ? 0.5 : 1

    // Use standard rate when enabled
    if (data.use_standard_rate) {
      const commission = Helpers.parseCurrency(data.commission_blre)

      sponsorBasisPct = 1
      basis = basis - commission + purchasePrice() * standardCommissionRate
    }

    // If the residual cap is less than the brokerage-side split, calculate the reduced commission to satisfy the cap.
    let brokerageSideSplit = (basis * (100 - leadAssociate.commission_pct)) / 100

    if (leadAssociate.cap_residual - brokerageSideSplit < 0) {
      basis = (parseFloat(leadAssociate.cap_residual) / brokerageSideSplit) * basis
    }

    //? Nick Helgesen's Personal CAP Strategy
    if (leadAssociate.id == 42 && basis > leadAssociate.cap) {
      basis = leadAssociate.cap
    }

    if (
      leadAssociate.sponsor1stTier &&
      !leadAssociate.sponsor1stTier.terminated &&
      !associates.find((transactionContact) => transactionContact.contact?.id == leadAssociate.sponsor1stTier.id)
    ) {
      team.push({
        type: 'Primary Sponsor',
        associate_id: leadAssociate.sponsor1stTier.id,
        name: leadAssociate.sponsor1stTier.name,
        percent: PRIMARY_SPONSOR_CUT * sponsorBasisPct,
        amount: Helpers.round(basis * (PRIMARY_SPONSOR_CUT / 100) * sponsorBasisPct),
      })
    }

    if (svp != null && !associates.find((transactionContact) => transactionContact.contact?.id == svp.id)) {
      team.push({
        type: `Support VP (${svp.states_supervising})`,
        associate_id: svp.id,
        name: svp.name,
        percent: SUPPORT_VP_CUT * sponsorBasisPct,
        amount: Helpers.round(basis * (SUPPORT_VP_CUT / 100) * sponsorBasisPct),
      })
    }

    if (
      leadAssociate.sponsor1stTier?.sponsor1stTier &&
      !leadAssociate.sponsor1stTier.sponsor1stTier.terminated &&
      !associates.find((transactionContact) => transactionContact.contact?.id == leadAssociate.sponsor1stTier.sponsor1stTier.id)
    ) {
      team.push({
        type: 'Secondary Sponsor',
        associate_id: leadAssociate.sponsor1stTier.sponsor1stTier.id,
        name: leadAssociate.sponsor1stTier.sponsor1stTier.name,
        percent: SECONDARY_SPONSOR_CUT * sponsorBasisPct,
        amount: Helpers.round(basis * (SECONDARY_SPONSOR_CUT / 100) * sponsorBasisPct),
      })
    }

    if (svp && isSVPTeamCommissionEligible(svp, team)) {
      // Remove svp from sponsors
      team = team.filter((sponsor) => sponsor.associate_id != svp.id)
    }

    setTeamSplits(team)
  }

  const isSVPTeamCommissionEligible = (svp, team) => {
    if (svp.team_cap > 0) {
      const totalSponsorCommissions = team.reduce((total, sponsor) => (total += sponsor.amount), 0)
      const standardSponsorCommissions = team.reduce((total, sponsor) => (total += sponsor.associate_id == svp.id ? sponsor.amount : 0), 0)
      const netOfficeCommission = officeAmount() - totalSponsorCommissions + standardSponsorCommissions
      const teamCommission = netOfficeCommission * 0.5

      if (teamCommission < svp.team_cap && teamCommission > standardSponsorCommissions) {
        return true
      }
    }
  }

  const onManualApprovalConfirmed = () => {
    router.post(
      route('transactions.agreements.store', { transaction: transaction.id }),
      { id: activeAssociate.id },
      {
        only: ['errors', 'flash', 'history', 'transaction'],
        onSuccess: () => {
          setApproving(false)
        },
      },
    )
  }

  if (!splits) {
    return <></>
  }

  return (
    <Fragment>
      {hasCommissions() ? (
        <Fragment>
          {splits
            ?.filter((split) => split.percent > 0)
            .map((split, index) => (
              <div key={index}>
                <div className="mb-0.5 flex items-end justify-between px-1">
                  <div
                    className={classNames(
                      'text-sm font-bold uppercase',
                      split.approved || associates.find((a) => a.id == split.id && !Boolean(a.invitation_accepted_at))
                        ? 'text-black'
                        : 'text-red-600',
                    )}
                  >
                    <div className="leading-none">{split.name}</div>
                    <span className="font-normal normal-case">
                      {split.associate_id == leadAssociate.id
                        ? 'Lead Associate'
                        : split.transaction_support
                          ? 'Transaction Support Associate'
                          : 'Co-Servicing Associate'}
                    </span>
                  </div>

                  {(split.user_id == user.id || Helpers.isAdminOrHR(user)) && !split.transaction_support && (
                    <div>
                      {split.cap > 0 ? (
                        <span className="text-sm">Remaining CAP: ${Helpers.formatDecimal(split.cap, 2)}</span>
                      ) : (
                        <span className="text-sm">CAPPED</span>
                      )}
                    </div>
                  )}
                </div>

                {!split.approved ? (
                  <div className="mb-2 mt-1 flex items-center gap-2">
                    <Alert
                      subtext={`${
                        split.user_id == user.id ? 'You have' : 'This associate has'
                      } not approved the Associate Co-Servicing Agreement for this transaction.`}
                      size="small"
                      type="warning"
                    >
                      {Helpers.isAdminOrHR(user) && (
                        <Alert.Actions>
                          <Button
                            type="button"
                            theme="solid"
                            colors="bg-orange-600 text-white hover:bg-opacity-75 focus:ring-2 focus:ring-orange-500"
                            className="flex h-auto items-center gap-4 !px-4 py-2 uppercase"
                            onClick={() => setActiveAssociate(split)}
                          >
                            {Helpers.isAdminOrHR(user) && <i className="fas fa-check text-lg"></i>}
                            <div>
                              <div className="text-lg font-semibold leading-tight">Approve</div>
                              <div className="text-sm leading-none text-white/75">Changes</div>
                            </div>
                          </Button>
                        </Alert.Actions>
                      )}
                    </Alert>
                  </div>
                ) : (
                  !associates.find((a) => a.id == split.id && Boolean(a.invitation_accepted_at)) && (
                    <div className="mb-2 mt-1 flex items-center gap-2">
                      <Alert
                        subtext={`${split.user_id == user.id ? 'You have' : 'This associate has'} not yet accepted the invitation to join the ${
                          transaction.type
                        } PRO Team for this transaction.`}
                        type="notice"
                      />
                    </div>
                  )
                )}

                {split.approved && (
                  <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                    <div className="flex divide-x divide-gray-300">
                      <div className="flex flex-1 justify-between bg-gray-100 px-4 py-2">
                        <div className="flex flex-col justify-center leading-tight">
                          <span>Commission</span>
                        </div>
                      </div>

                      <span className="flex w-20 items-center justify-center truncate px-2 py-2 font-medium">
                        <span>{Helpers.truncateDecimals(split.percent, '%')}</span>
                      </span>

                      <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
                        $
                        <span className="truncate">
                          {split.transaction_support
                            ? Helpers.formatDecimal(split.amount, 2)
                            : Helpers.formatDecimal(Helpers.parseCurrency(netRetained()) * (split.percent / 100), 2)}
                        </span>
                      </span>
                    </div>

                    {data.primary_residence && leadAssociate.user_id == split.user_id ? (
                      <div className="flex divide-x divide-gray-300">
                        <span className="flex flex-1 items-center justify-between bg-gray-100 px-4 py-2">
                          <span>Primary Residence Fee</span>
                          <i className="fas fa-minus text-red-600"></i>
                        </span>

                        <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                          $<span className="truncate">{Helpers.formatDecimal(Helpers.parseCurrency(split.office_retained || 0), 2)}</span>
                        </span>
                      </div>
                    ) : (
                      split.office_retained > 0 &&
                      (split.user_id == user.id || Helpers.isAdminOrHR(user)) && (
                        <div className="flex divide-x divide-gray-300">
                          <span className="flex flex-1 items-center justify-between bg-gray-100 px-4 py-2">
                            <span className="leading-none">
                              {split.office_retained === minimumCapFee ? (
                                'Min Cap Fee'
                              ) : split.office_retained === split.cap ? (
                                'Remaining Cap'
                              ) : (
                                <Fragment>
                                  {split.cap_pct < 100
                                    ? `${Helpers.truncateDecimals(split.cap_pct, '%')} of Brokerage-side Split`
                                    : 'Brokerage-side Split'}

                                  {data.use_standard_rate && (
                                    <div className="text-xs font-semibold uppercase text-red-500">
                                      Using standard rate - {Helpers.parseCurrency(standardCommissionRate) * 100}%
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            </span>
                            <i className="fas fa-minus text-red-600"></i>
                          </span>

                          {split.office_retained !== minimumCapFee && split.office_retained !== split.cap && (
                            <span className="flex w-20 items-center justify-center truncate px-2 py-2 font-medium">
                              <span>{Helpers.truncateDecimals(100 - split.commission_pct, '%')}</span>
                            </span>
                          )}

                          <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                            $<span className="truncate">{Helpers.formatDecimal(split.office_retained || 0, 2)}</span>
                          </span>
                        </div>
                      )
                    )}

                    {transaction_support_enabled && !split.transaction_support && (
                      <div className="flex divide-x divide-gray-300">
                        <div className="flex flex-1 justify-between bg-gray-100 px-4 py-2">
                          <div className="flex items-center">
                            <span>Transaction Support Fee</span>
                          </div>

                          <div className="flex flex-col justify-center font-medium leading-tight">
                            <i className="fas fa-minus text-red-600"></i>
                          </div>
                        </div>

                        <span className="flex w-20 items-center justify-center truncate px-2 py-2 font-medium">
                          <span>{transactionSupportPct()}%</span>
                        </span>

                        <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                          $<span className="truncate">{Helpers.formatDecimal(transactionSupportFee(split), 2)}</span>
                        </span>
                      </div>
                    )}

                    {!split.transaction_support && Helpers.parseCurrency(split.referral_deduction) > 0 && (
                      <div className="flex divide-x divide-gray-300">
                        <div className="flex flex-1 justify-between bg-gray-100 px-4 py-2">
                          <div className="flex items-center">
                            <span>
                              Referral: <span className="font-semibold text-blue-500">{data.referral} </span>
                            </span>
                          </div>

                          <div className="flex flex-col justify-center font-medium leading-tight">
                            <i className="fas fa-minus text-red-600"></i>
                          </div>
                        </div>

                        <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                          $<span className="truncate">{Helpers.formatDecimal(Helpers.parseCurrency(split.referral_deduction), 2)}</span>
                        </span>
                      </div>
                    )}

                    {data.additional_compensation
                      .filter(
                        (compensation) =>
                          Helpers.parseCurrency(compensation.amount) > 0 &&
                          !split.transaction_support &&
                          (compensation.deducted_from_id == split.associate_id ||
                            compensation.deducted_from === 'Entire Associate-Side Split'),
                      )
                      .map((compensation, index) => (
                        <div className="flex divide-x divide-gray-300" key={index}>
                          <div className="flex flex-1 justify-between bg-gray-100 px-4 py-2">
                            <div>
                              <div className="font-semibold leading-none text-gray-700">{compensation.associate_name}</div>
                              {compensation.comments ? (
                                <div className="text-sm font-medium text-gray-500">{compensation.comments}</div>
                              ) : (
                                <div className="text-xs font-semibold uppercase text-gray-400">Additional Compensation</div>
                              )}
                            </div>

                            <div className="flex flex-col justify-center font-medium leading-tight">
                              <i className="fas fa-minus text-red-600"></i>
                            </div>
                          </div>

                          <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                            <span className="truncate">
                              {compensation.deducted_from_id == split.associate_id
                                ? '$' + Helpers.formatDecimal(Helpers.parseCurrency(compensation.amount), 2)
                                : compensation.deducted_from === 'Entire Associate-Side Split'
                                  ? '$' +
                                    Helpers.formatDecimal(
                                      Helpers.parseCurrency(compensation.amount) /
                                        associates.filter((split) => !split.transaction_support).length,
                                      2,
                                    )
                                  : ''}
                            </span>
                          </span>
                        </div>
                      ))}

                    {Helpers.parseCurrency(split.client_rebate_deduction) > 0 && (
                      <div className="flex divide-x divide-gray-300">
                        <span className="flex flex-1 items-center justify-between bg-gray-100 px-4 py-2">
                          <span>Rebate to Client:</span>
                          <i className="fas fa-minus text-red-600"></i>
                        </span>
                        <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                          $
                          <span className="truncate">{Helpers.formatDecimal(Helpers.parseCurrency(split.client_rebate_deduction), 2)}</span>
                        </span>
                      </div>
                    )}

                    {(split.user_id == user.id || Helpers.isAdminOrHR(user)) && (
                      <div>
                        <div className="flex divide-x divide-gray-300 border-t border-gray-400">
                          <div className="flex flex-1 items-center justify-between bg-blue-100 px-4 py-2 leading-none">
                            <div className="font-bold">NET Commission</div>

                            {netCommission(split) < 0 && (
                              <div className="flex flex-col justify-center font-medium leading-tight">
                                <i className="fas fa-minus text-red-600"></i>
                              </div>
                            )}
                          </div>
                          <span
                            className={classNames('flex w-32 items-center justify-center bg-blue-100 px-4 py-2 font-bold', {
                              'text-red-600': netCommission(split) < 0,
                            })}
                          >
                            <span className="truncate">
                              {Helpers.formatCurrency(netCommission(split) < 0 ? netCommission(split) * -1 : netCommission(split), 2)}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}

          {/* SPONSOR SPLITS */}
          {Helpers.isAdminOrHR(user) && !data.primary_residence && teamSplits?.length > 0 && (
            <div>
              <span className="mb-0.5 text-sm font-bold uppercase text-black">Sponsors</span>

              <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                {teamSplits.map((associate, index) => (
                  <div className="flex divide-x divide-gray-300" key={index}>
                    <div className="flex-1 flex-col justify-center bg-gray-100 px-4 py-2 leading-none">
                      <div className="font-bold">{associate.name}:</div>
                      <div className="text-xs font-semibold uppercase text-gray-400">{associate.type}</div>
                    </div>
                    <span className="flex w-20 items-center justify-center truncate px-2 py-2 font-medium">
                      {Helpers.truncateDecimals(associate.percent, '%')}
                    </span>
                    <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
                      $<span className="truncate">{Helpers.formatDecimal(associate.amount || 0, 2)}</span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* ADDL COMPENSATION PAID BY BROKERAGE */}
          {brokerageSideDeductions.length > 0 && (
            <div>
              <div className="mb-0.5 text-sm font-bold uppercase text-black">
                <div className="leading-none">Additional Compensation</div>
                <span className="font-normal normal-case">(Paid by the Brokerage)</span>
              </div>

              <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                {brokerageSideDeductions.map((payment) => (
                  <div className="flex divide-x divide-gray-300" key={payment.id}>
                    <div className="flex flex-1 justify-between bg-gray-100 px-4 py-2">
                      <div>
                        <div className="font-semibold leading-none text-gray-700">{payment.associate_name}</div>
                        {payment.comments ? (
                          <div className="text-sm font-medium text-gray-500">{payment.comments}</div>
                        ) : (
                          <div className="text-xs font-semibold uppercase text-gray-400">Additional Compensation</div>
                        )}
                      </div>

                      <div className="flex flex-col justify-center font-medium leading-tight">
                        <i className="fas fa-minus text-red-600"></i>
                      </div>
                    </div>

                    <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                      $<span className="truncate">{Helpers.formatDecimal(payment.amount, 2)}</span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* BROKERAGE-SIDE SPLIT */}
          {Helpers.isAdminOrHR(user) && (
            <div>
              <span className="mb-0.5 text-sm font-bold uppercase text-black">Office</span>

              {splits.find((split) => !split.approved) ? (
                <div className="mb-2 mt-1 flex items-center gap-2">
                  <Alert
                    subtext="1 or more Associates have not approved their Co-Servicing Agreement for this transaction."
                    size="small"
                    type="warning"
                  />
                </div>
              ) : (
                <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                  <div className="flex items-center divide-x divide-gray-300 bg-gray-100">
                    <div className="flex flex-1 justify-between px-4">
                      <div>
                        <div className="leading-none text-gray-700">Retained</div>
                      </div>
                    </div>

                    <span className="flex w-32 items-center justify-center bg-white px-4 py-2 font-bold">
                      $<span className="truncate">{Helpers.formatDecimal(officeAmount() || 0, 2)}</span>
                    </span>
                  </div>

                  {!data.primary_residence && teamSplits?.length > 0 && (
                    <div className="flex divide-x divide-gray-300">
                      <div className="flex flex-1 justify-between bg-gray-100 px-4 py-2">
                        <div>Sponsor Commissions</div>

                        <div className="flex flex-col items-center justify-center font-medium leading-tight">
                          <i className="fas fa-minus text-red-600"></i>
                        </div>
                      </div>

                      <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                        $
                        <span className="truncate">
                          {Helpers.formatDecimal(
                            teamSplits.reduce((total, payment) => total + payment.amount, 0),
                            2,
                          )}
                        </span>
                      </span>
                    </div>
                  )}

                  {brokerageSideDeductions.map((payment, index) => (
                    <div className="flex divide-x divide-gray-300" key={index}>
                      <div className="flex flex-1 justify-between bg-gray-100 px-4 py-2">
                        <div>
                          <div className="font-semibold leading-none text-gray-700">{payment.associate_name}</div>
                          {payment.comments ? (
                            <div className="text-sm font-medium text-gray-500">{payment.comments}</div>
                          ) : (
                            <div className="text-xs font-semibold uppercase text-gray-400">Additional Compensation</div>
                          )}
                        </div>

                        <div className="flex flex-col justify-center font-medium leading-tight">
                          <i className="fas fa-minus text-red-600"></i>
                        </div>
                      </div>

                      <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                        $<span className="truncate">{Helpers.formatDecimal(payment.amount, 2)}</span>
                      </span>
                    </div>
                  ))}

                  {transaction.broker_of_record?.compensation_pct > 0 && (
                    <div className="flex items-center divide-x divide-gray-300 bg-gray-100">
                      <div className="flex flex-1 items-center justify-between bg-gray-100 px-4 py-2 leading-none">
                        <span>
                          {transaction.broker_of_record.state} Broker of Record:{' '}
                          <span className="font-semibold text-blue-500">{transaction.broker_of_record.name}</span>
                        </span>
                        <i className="fas fa-minus text-red-600"></i>
                      </div>

                      <span className="flex w-20 items-center justify-center truncate bg-white px-2 py-2 font-medium">
                        {Helpers.formatDecimal(transaction.broker_of_record.compensation_pct)}%
                      </span>

                      <span className="flex w-32 items-center justify-center bg-white px-4 py-2 font-bold text-red-600">
                        $
                        <span className="truncate">
                          {Helpers.formatDecimal((netRetained() || 0) * (transaction.broker_of_record.compensation_pct / 100), 2)}
                        </span>
                      </span>
                    </div>
                  )}

                  {Boolean(teamCap) && (
                    <div className="flex divide-x divide-gray-300">
                      <div className="flex flex-1 justify-between bg-gray-100 px-4 py-2">
                        <div className="flex items-center gap-1.5">
                          <span className="font-semibold text-blue-500">{svp.name}</span> Team Commission
                          <Tooltip
                            label="Changes made to compensation will not be reflected here until they have been saved."
                            placement="bottom"
                          >
                            <i className="far fa-question-circle text-base text-orange-500"></i>
                          </Tooltip>
                        </div>

                        <div className="flex flex-col items-center justify-center font-medium leading-tight">
                          <i className="fas fa-minus text-red-600"></i>
                        </div>
                      </div>

                      <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                        $<span className="truncate">{Helpers.formatDecimal(teamCap, 2)}</span>
                      </span>
                    </div>
                  )}

                  <div>
                    <div className="flex divide-x divide-gray-300 border-t border-gray-400 bg-blue-100">
                      <div className="flex flex-1 items-center justify-between px-4 py-2 leading-none">
                        <div className="font-bold">NET Commission</div>
                      </div>
                      <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
                        $<span className="truncate">{Helpers.formatDecimal(netOfficeRetained() || 0, 2)}</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Fragment>
      ) : (
        <Alert
          heading={
            ['Buyer', 'Seller'].some((type) => type.includes(transaction.type))
              ? `Total Purchase Price & ${transaction.type} Agent Commission are required to generate commissions.`
              : `${transaction.type} Agent Commission is required to generate commissions.`
          }
          align="center"
        />
      )}

      <ConfirmationDialog
        open={approving}
        title={`Confirm Manual Approval for ${activeAssociate?.name}`}
        onCanceled={() => setApproving(false)}
        onApproved={() => onManualApprovalConfirmed()}
      >
        <Fragment>
          <p className="mb-3">
            You are about to manually approve the <span className="font-semibold">Associate Co-Servicing Agreement</span> for{' '}
            <span className="font-semibold text-blue-500">{activeAssociate?.name}</span>. Performing this action will notify the associate
            and record that you performed this activity on behalf of the associate.
          </p>

          <p className="mb-3">Would you like to continue?</p>
        </Fragment>
      </ConfirmationDialog>
    </Fragment>
  )
}
